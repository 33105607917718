import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useSingleRoomAPI = (room_id) => {
    const { getAccessTokenSilently } = useAuth0();
    const [room, setRoom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshRoom, setRefreshRoom] = useState(0);

    const refresh = () => {
        console.log("Trying to refresh single room");
        setRefreshRoom(refreshRoom + 1);
    };

    useEffect(() => {
        if (room_id) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                        scope: "read:classrooms",
                    });
                    const response = await fetch(
                        process.env.REACT_APP_MM_API_URL +
                            "rooms/detail/" +
                            room_id,
                        {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    );                     
                    
                    if(response.status === 403) {
                        setError(true);
                        setLoading(false);
                        } else {
                            setRoom(await response.json());
                            setLoading(false);
                        }

                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    setError(e);
                }
            })();
        }
    }, [getAccessTokenSilently, room_id, refreshRoom]);

    return [room, loading, error, refresh];
};

export default useSingleRoomAPI;
